import axios from "axios";
import URLS from "./apiUrls";

export function addScript(document, id, src, innerHTML) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.id = id;
    if (innerHTML) {
        script.innerHTML = innerHTML;
    }
    document.getElementsByTagName("head")[0].appendChild(script);
    return script;
}

export function formatBytes(bytes, decimals = 2) {
    if (isNaN(bytes)) {
        return NaN;
    }
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function abbreviateNumber(number, abbreviationLimit = Number.MIN_SAFE_INTEGER) {
    if (!number) return number;
    if (number < abbreviationLimit) return number.toString();

    let SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];

    // what tier? (determines SI symbol)
    let tier = Math.floor(Math.log10(Math.abs(number)) / 3);

    // if zero, we don't need a suffix
    if (tier == 0) return number;

    // get suffix and determine scale
    let suffix = SI_SYMBOL[tier];
    let scale = Math.pow(10, tier * 3);

    // scale the number
    let scaled = number / scale;

    // format number and add suffix
    return parseFloat(scaled.toFixed(1)) + suffix;
}

export function formatToBytes(size, unit) {
    size = parseFloat(size);
    const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    if (!units.includes(unit)) return NaN;
    if (size === 0) return 0;
    const k = 1024;
    return size * Math.pow(k, units.indexOf(unit));
}

export function isZoneSlug(text) {
    if (text) {
        return text.match(/^[a-zA-Z0-9_-]{6}$/);
    } else {
        return null;
    }
}

export function getImageUrlWithOptions(url, pattern) {
    // url = url.replace("https://cdn.pixelbinz0.de", "http://localhost:9020"); // to test transformation locally

    const { protocol } = new URL(url);
    const urlArr = url.replace(`${protocol}//`, "").split("/");
    const isVersionedUrl = ["v1", "v2"].includes(urlArr[1]);

    if (isZoneSlug(urlArr[2 + isVersionedUrl])) {
        // URL is a zone URL: [cdn_url,[ version,] cloudName, zone-slug, pattern, relative_path...]
        // 3 + true/false
        urlArr[3 + isVersionedUrl] = pattern;
    } else {
        // URL is a zone less (default zone) URL: [cdn_url,[ version,] cloudName, pattern, relative_path...]
        // 2 + true/false
        urlArr[2 + isVersionedUrl] = pattern;
    }

    return `${protocol}//${urlArr.join("/")}`;
}

export function getImageUrlWithPattern(url, pattern, position) {
    // url = url.replace("https://cdn.pixelbinz0.de", "http://localhost:9020"); // to test transformation locally

    const { protocol } = new URL(url);
    const urlArr = url.replace(`${protocol}//`, "").split("/");
    const isVersionedUrl = ["v1", "v2"].includes(urlArr[1]);

    if (isZoneSlug(urlArr[2 + isVersionedUrl])) {
        // URL is a zone URL: [cdn_url,[ version,] cloudName, zone-slug, pattern, relative_path...]
        // 3 + true/false
        if (urlArr[3 + isVersionedUrl] === "original") {
            urlArr[3 + isVersionedUrl] = pattern;
        } else {
            urlArr[3 + isVersionedUrl] =
                position === "start"
                    ? `${pattern}~${urlArr[3 + isVersionedUrl]}`
                    : `${urlArr[3 + isVersionedUrl]}~${pattern}`;
        }
    } else {
        // URL is a zone less (default zone) URL: [cdn_url,[ version,] cloudName, pattern, relative_path...]
        // 2 + true/false
        if (urlArr[2 + isVersionedUrl] === "original") {
            urlArr[2 + isVersionedUrl] = pattern;
        } else {
            urlArr[2 + isVersionedUrl] =
                position === "start"
                    ? `${pattern}~${urlArr[2 + isVersionedUrl]}`
                    : `${urlArr[2 + isVersionedUrl]}~${pattern}`;
        }
    }

    return `${protocol}//${urlArr.join("/")}`;
}

export function isValidDate(date) {
    if (date) {
        // First check for the pattern
        if (!/^\d{4}\-\d{1,2}\-\d{1,2}$/.test(date)) return false;

        var parts = date.split("-");
        var day = parseInt(parts[2], 10);
        var month = parseInt(parts[1], 10);
        var year = parseInt(parts[0], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

        // Adjust for leap years
        if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;

        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    }
    return false;
}

export function customThrottle(func, limit) {
    let lastFunc;
    let lastRan;
    return {
        throttledFunction: function () {
            const context = this;
            // eslint-disable-next-line prefer-rest-params
            const args = arguments;
            if (!lastRan) {
                func.apply(context, args);
                lastRan = Date.now();
            } else {
                clearTimeout(lastFunc);
                lastFunc = setTimeout(function () {
                    if (Date.now() - lastRan >= limit) {
                        func.apply(context, args);
                        lastRan = Date.now();
                    }
                }, limit - (Date.now() - lastRan));
            }
        },
        clearLastFunctionCall: function () {
            if (lastFunc) {
                clearTimeout(lastFunc);
            }
        },
    };
}

export const getPercentage = (value, total, numOfDigits) => {
    let percentage = (value / total) * 100;
    return percentage.toFixed(numOfDigits);
};

export const formatDigits = (value) => {
    const trimmed = parseFloat(parseFloat(value).toFixed(3));
    if (Math.floor(trimmed) === trimmed) return Math.floor(trimmed);
    return trimmed;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export function daysToMonthsOrYears(days) {
    const months = Math.floor(days / 30.44);
    const years = Math.floor(days / 365.25);

    if (years >= 1) {
        return `${years} year${years > 1 ? "s" : ""}`;
    } else if (months >= 1) {
        return `${months} month${months > 1 ? "s" : ""}`;
    } else {
        return `${days} day${days > 1 ? "s" : ""}`;
    }
}

export function base64Encode(text) {
    const encodedStr = btoa(text);
    return encodedStr;
}

export function jsonToQueryString(json) {
    return new URLSearchParams(json).toString();
}

export function arrayBufferToImageURL(arrayBuffer, initialWidth, initialHeight) {
    const canvas = document.createElement("canvas");
    canvas.width = initialWidth;
    canvas.height = initialHeight;

    const ctx = canvas.getContext("2d");

    const imageData = new ImageData(
        new Uint8ClampedArray(arrayBuffer),
        initialWidth,
        initialHeight,
    );

    ctx.putImageData(imageData, 0, 0);

    return canvas.toDataURL();
}

export const getCurrentSession = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios.get(URLS.currentUserSession);
            const data = response.data;
            resolve(data);
        } catch (error) {
            reject(error);
        }
    });
};

export const getChangedFormat = (imageSrc) => {
    const src = new URL(imageSrc);

    if (!src.searchParams.get("changeFormat")) {
        // PNG has an alpha channel, hence using that
        src.href = getImageUrlWithPattern(src.href, "t.toFormat(f:png)");

        let params = new URLSearchParams(src.search);
        params.append("changeFormat", "true");
        src.search = params.toString();

        return [false, src.href];
    }

    return [true, src.href];
};

export const addParamsInUrl = (url, params) => {
    let newUrl = new URL(url);

    Object.entries(params).forEach(([param, value]) => {
        newUrl.searchParams.set(param, value);
    });

    return newUrl.toString();
};
