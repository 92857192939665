import { useLocation } from "react-router";
import urlJoin from "url-join";

import environment from "../../environment";
import { sign } from "services/interceptors/signature";
import _ from "lodash";
import {
    ANONYMOUS_ID_KEY,
    ANONYMOUS_ID_PARAM_KEY,
    SEGMENT_ANONYMOUS_ID_KEY,
} from "../constants/constants";
import { isValidPhoneNumber } from "react-phone-number-input";
import FreePropertyGuard from "@src/guards/freeproperty.guard";

export function debounce(func, wait) {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export function convertEpoch(epoch) {
    epoch *= 1000;
    const date = new Date(epoch);
    const year = date.getFullYear();
    if (year === 1970) {
        return "NA";
    }
    return date.getDate() + ", " + MONTHS[date.getMonth()] + ", " + year;
}

export function toUpperCase(s) {
    return s.toUpperCase()[0] + s.slice(1);
}

/* istanbul ignore next */
function base64Encode(text) {
    return Buffer.from(text).toString("base64");
}

export function generateSignature(url, payload) {
    const { method, body, headers } = payload;
    const { host, pathname, search } = new URL(url);
    const signingOptions = {
        method: method && method.toUpperCase(),
        host: host,
        path: pathname + search,
        body: body,
        headers,
    };
    sign(signingOptions);
    payload = {
        ...payload,
        headers: {
            "x-ebg-signature": signingOptions.headers["x-ebg-signature"],
            "x-ebg-param": base64Encode(signingOptions.headers["x-ebg-param"]),
        },
    };
    return payload;
}

export function handleScroll(container, dir = "left") {
    if (dir.toLowerCase() === "left") {
        container.scrollLeft += container.clientWidth;
    } else {
        container.scrollLeft -= container.clientWidth;
    }
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function docLink(path) {
    return urlJoin(environment.SAMUI_MAIN_DOMAIN, path);
}

export const selKeys = (keys, obj) =>
    obj &&
    Object.entries(obj)
        .filter(([key]) => keys.includes(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export function getUtmSource() {
    let utmSource = sessionStorage.getItem("utm_source");
    return utmSource ? utmSource : undefined;
}

export function setUtmSource(utmSource) {
    if (utmSource && typeof window !== "undefined") sessionStorage.setItem("utm_source", utmSource);
}

export function clearUtmSource() {
    sessionStorage.removeItem("utm_source");
}

export function swap(arr, fromIdx, toIdx) {
    return Object.assign([], arr, { [fromIdx]: arr[toIdx], [toIdx]: arr[fromIdx] });
}

export function removeAt(arr, idx) {
    return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
}

export function getCookie(key) {
    const cookie = document.cookie
        .split("; ")
        .find((row) => row.startsWith(`${key}=`))
        ?.split("=")[1];
    return cookie ? cookie.replaceAll('"', "") : null;
}

export function getPublicPropAnonymousId() {
    try {
        if (typeof window !== "undefined") {
            return (
                // check query param
                getCookie(ANONYMOUS_ID_KEY) ||
                new URLSearchParams(window.location.search).get(ANONYMOUS_ID_PARAM_KEY) ||
                sessionStorage.getItem(ANONYMOUS_ID_PARAM_KEY) ||
                localStorage.getItem(SEGMENT_ANONYMOUS_ID_KEY)?.replaceAll('"', "") ||
                getCookie(SEGMENT_ANONYMOUS_ID_KEY)
            );
        } else return null;
    } catch (err) {
        return null;
    }
}

export function setPublicPropAnonymousId(anonymousId) {
    if (anonymousId && typeof window !== "undefined")
        sessionStorage.setItem(ANONYMOUS_ID_PARAM_KEY, anonymousId);
}

export const resetSegmentUserId = () => {
    const anonymousId = getPublicPropAnonymousId();
    window?.analytics && window?.analytics?.reset();
    window?.analytics?.identify(
        null,
        {},
        {
            ...(anonymousId && {
                anonymousId: anonymousId,
            }),
        },
    );
};

export function initializeSegmentIdentity(user = {}, otherTraits = {}) {
    let publicPropAnonymousId = getPublicPropAnonymousId();
    const userTraits = _.pick(user, ["email", "firstName", "lastName", "createdAt"]) || {};
    window?.analytics?.identify(
        user?._id || null,
        {
            ...userTraits,
            ...otherTraits,
        },
        {
            ...(publicPropAnonymousId && {
                anonymousId: publicPropAnonymousId,
            }),
        },
    );
}

// Define a custom validator function that considers an empty input valid if the user is typing something
export const validatePhoneNumber = (value) => {
    // If the value is not empty or null, use the validator from react-phone-number-input
    if (value && value.trim().length > 0) {
        return isValidPhoneNumber(value) ? true : false;
    }
    // Otherwise, check if the user is typing something
    return !value ? true : false;
};

export function ignoreHiddenItem(name) {
    return !name?.startsWith(".");
}

export function convertBlobToJson(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
            try {
                const text = reader.result; // Get the text from the FileReader
                const json = JSON.parse(text); // Parse the text as JSON
                resolve(json);
            } catch (error) {
                reject(error);
            }
        };

        reader.onerror = function (error) {
            reject(error);
        };

        reader.readAsText(blob); // Read the blob as text
    });
}

export function getRedirectUrl() {
    let redirectUrl = sessionStorage.getItem("redirectUrl");
    return redirectUrl ? redirectUrl : undefined;
}

export function storeRedirectUrl(redirectUrl) {
    if (redirectUrl && typeof window !== "undefined")
        sessionStorage.setItem("redirectUrl", redirectUrl);
}

export function clearRedirectUrl() {
    sessionStorage.removeItem("redirectUrl");
}

export function setRedirectTo(redirectTo) {
    try {
        if (!(redirectTo && typeof window !== "undefined")) return;

        let expirationDate = new Date();
        expirationDate.setHours(expirationDate.getHours() + 1); // Set expiration time 1 hour from now
        let item = {
            value: redirectTo,
            expiry: expirationDate.getTime(),
        };
        localStorage.setItem("redirectTo", JSON.stringify(item));
    } catch (err) {
        console.log(err);
    }
}

export function getRedirectTo(consumeRedirectTo = true) {
    try {
        let storedItem = localStorage.getItem("redirectTo");
        if (storedItem !== null) {
            storedItem = JSON.parse(storedItem);

            let now = new Date().getTime();
            // Compare the expiration time with the current time
            if (now > storedItem.expiry) {
                // If the item is expired, don't use it. This will help keep the localStorage clean
                storedItem = null;
            }

            if (consumeRedirectTo) {
                // Remove the item from storage so its not used again, and users are not stuck in a loop
                localStorage.removeItem("redirectTo");
            }
        }
        return storedItem?.value; // Return the value if it exists, or undefined
    } catch (err) {
        console.log(err);
    }
}

export function normalizeQueryParam(value) {
    if (value === "null" || value === "undefined") {
        return null;
    }
    return value;
}

export function logError(error) {
    const { status, statusText, config } = error.response || {};
    const { method, url, data } = config || {};
    console.error(`Error: ${status} ${statusText}`);
    console.error(`Method: ${method}`);
    console.error(`URL: ${url}`);
    data && console.error(`Data: ${JSON.stringify(data)}`);
}

// Utility function to initialize and return a FreePropertyGuard instance
export function initializeFreePropertyGuard() {
    // Initialize URLSearchParams without any query initially
    const queryParams = new URLSearchParams();

    const redirectToFreePropertyFlow = sessionStorage.getItem("redirectToFreePropertyFlow");
    const utm_source = sessionStorage.getItem("utm_source");

    // Conditionally append each parameter if it exists
    if (redirectToFreePropertyFlow) {
        queryParams.append("redirectToFreePropertyFlow", redirectToFreePropertyFlow);
        // Remove the item from sessionStorage after using it
        sessionStorage.removeItem("redirectToFreePropertyFlow");
    }

    if (utm_source) {
        queryParams.append("utm_source", utm_source);
    }

    // Initialize the guard with the queryParams and return it
    return new FreePropertyGuard(queryParams);
}

export function getFPTid() {
    return window.FPROM && window.FPROM.data.tid;
}
