import React, { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";

const ResizeContext = createContext({
    showResizePortal: false,
    setShowResizePortal: () => {},
    showBulkTransformPanel: false,
    closeResizePortal: () => {},
});

const initialResizeStatus = {
    onChange: () => {}, // call to update input
};

const initialGizmo = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
};

const ResizeContextProvider = ({ children }) => {
    const { originalDetails } = useSelector((state) => state.playgroundDetails);
    const [previewTab, setPreviewTab] = useState("original");
    const [showResizePortal, setShowResizePortal] = useState(false); // manage resize portal
    const [imageSrc, setImageSrc] = useState(null);
    const [resizeStatus, setResizeStatus] = useState(initialResizeStatus); // manage resized input values
    const [gizmo, setGizmo] = useState(initialGizmo); // wrapper around image
    const [resizedDimensions, setResizedDimensions] = useState("");
    const [scaledImage, setScaledImage] = useState({ width: 0, height: 0 });
    const [gizmoImageResize, setGizmoImageResize] = useState({
        width: originalDetails.width,
        height: originalDetails.height,
    });
    const [scalingInverseFactor, setScalingInverseFactor] = useState({
        scaleFactor: 0,
        invScaleFactor: 0,
    });
    const [inputError, setInputError] = useState(null);

    const gizmoLimits = 10000;

    const closeResizePortal = () => {
        setShowResizePortal(false);
        setGizmo(initialGizmo);
        setImageSrc(null);
    };

    const resetResizeStatus = () => {
        setResizeStatus(initialResizeStatus);
        setInputError(null);
    };

    return (
        <ResizeContext.Provider
            value={{
                showResizePortal,
                setShowResizePortal,
                closeResizePortal,
                resizeStatus,
                setResizeStatus,
                gizmo,
                setGizmo,
                resizedDimensions,
                setResizedDimensions,
                scaledImage,
                setScaledImage,
                imageSrc,
                setImageSrc,
                previewTab,
                setPreviewTab,
                gizmoImageResize,
                setGizmoImageResize,
                scalingInverseFactor,
                setScalingInverseFactor,
                gizmoLimits,
                resetResizeStatus,
                inputError,
                setInputError,
            }}
        >
            {children}
        </ResizeContext.Provider>
    );
};

const useResizeContext = () => useContext(ResizeContext);

export { ResizeContextProvider, useResizeContext };
